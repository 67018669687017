export default function Hackathons() {
  return (
    <>
      <div>
        <div className="heading">
          <h1>Hackathons Participation</h1>
        </div>

        
      </div>
    </>
  );
}
